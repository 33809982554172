import { default as React } from 'react';
import { DocumentVerificationStep } from '../../containers/DocumentVerification';
import { ImageResult, ImageType } from '../../shared';

const TakeSelfieComplete = (): JSX.Element => {
  return (
    <ImageResult
      tryAgainStep={DocumentVerificationStep.TakeSelfieStart}
      nextStep={DocumentVerificationStep.Finish}
      imageType={ImageType.Selfie}
    />
  );
};

export default TakeSelfieComplete;
