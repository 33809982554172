import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const FilledButton = styled(Button)({
    background: '#f27023',
    border: 0,
    width: '80%',
    marginRight: '10px',
    marginLeft: '10px',
    borderRadius: 4,
    boxShadow: 'none',
    color: 'white',
    fontSize: 14,
    height: 30,
    padding: '0 30px',
    textTransform: 'none'
});