import {default as React, useContext, useEffect, useState} from 'react';
import {AppContext} from '../../containers/AppProvider';
import queryString from 'query-string';
import {Container, Button, Grid, Typography} from '@material-ui/core';
import {uploadScanedImages} from "../../containers/DocumentVerification/api";
import Swal from 'sweetalert2';
import {getStringTranslation, stringKeys} from "../../helpers/translate";
import {finishStepStyles} from "../constants";

enum UploadState {
    Done,
    Error,
    Waiting,
    Uploading,
}

const Finish = (): JSX.Element => {
    const {token = null, redirectUrl = null} = queryString.parse(window.location.search);
    const classes = finishStepStyles();
    const {state} = useContext(AppContext);
    const {selfie, frontImage, backImage} = state.photo;
    const [docUploadState, setDocUploadState] = useState<UploadState>(UploadState.Waiting);
    const [alertShown, setAlertShown] = useState<boolean>(false);

    useEffect(() => {
        if (docUploadState === UploadState.Done && !alertShown) {
            (async () => {
                setAlertShown(true);
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: 'success',
                    title: 'Success',
                    text: getStringTranslation(state.photo.isMobileFlow ? stringKeys.mobileFlowSuccess : stringKeys.desktopFlowSuccess)
                });

                if (redirectUrl) {
                    window.location.href = redirectUrl as string
                }
            })();
        }

        if (docUploadState === UploadState.Uploading || docUploadState === UploadState.Done) {
            return;
        }

        startUpload();
    });

    const startUpload = () => {
        setDocUploadState(UploadState.Uploading);
        uploadScanedImages(token as string, state.photo.isMobileFlow, frontImage, backImage, selfie).then(response => {
            setDocUploadState(response.success ? UploadState.Done : UploadState.Error);
        }).catch(error => {
            setDocUploadState(UploadState.Error);
        });
    }

    let content = <Typography component="h1" variant="h5" className={classes.title}>
        {getStringTranslation(stringKeys.continueVerify)}
    </Typography>;

    if (docUploadState === UploadState.Uploading) {
        content = <Typography component="h1" variant="h5" className={classes.title}>
            {getStringTranslation(stringKeys.uploadingPleaseWait)}
        </Typography>;
    }

    if (docUploadState === UploadState.Waiting) {
        content = <Typography component="h1" variant="h5" className={classes.title}>
            {getStringTranslation(stringKeys.waitingUpload)}
        </Typography>;
    }

    if (docUploadState === UploadState.Error) {
        content = <Button variant="contained" color="primary" onClick={startUpload}>
            {getStringTranslation(stringKeys.tryAgain)}
        </Button>;
    }

    return (
        <Container>
            <Grid container spacing={4} direction="column" justify="center" alignItems="center">
                <Grid item xs={10}>
                    {content}
                </Grid>
            </Grid>
        </Container>
    );
};

export default Finish;
