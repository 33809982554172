import { default as React } from 'react';
import { DocumentVerificationStep } from '../../containers/DocumentVerification';
import { ImageResult, ImageType } from '../../shared';
import {getNextStep} from "../../containers/DocumentVerification/StepsResolver";

const ScanFrontImageComplete = (): JSX.Element => {
  return (
    <ImageResult
      tryAgainStep={DocumentVerificationStep.ScanFrontImageStart}
      nextStep={getNextStep(DocumentVerificationStep.ScanFrontImageComplete) || DocumentVerificationStep.Finish}
      imageType={ImageType.FrontImage}
    />
  );
};

export default ScanFrontImageComplete;
