import { default as React, useContext } from 'react';
import { DocumentVerificationStep, DocumentVerificationContext } from '../../containers/DocumentVerification';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { FilledButton } from "../../styles/FilledButton";
import MobileStepper from '@material-ui/core/MobileStepper';
import {getStringTranslation, stringKeys} from "../../helpers/translate";
import {getStepperPosition} from "../../containers/DocumentVerification/StepsResolver";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(7)
  },
  subtitle: {
    lineHeight: '1.5em'
  },
  image: {
    height: theme.spacing(9),
    margin: theme.spacing(1, 0, 0)
  },
  buttonWrapper: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(15)
  }
}));

const ScanBackImageIntro = (): JSX.Element => {
  const classes = useStyles();
  const { onChangeStep } = useContext(DocumentVerificationContext);

  const handleNext = (): void => {
    onChangeStep(DocumentVerificationStep.ScanBackImageStart);
  };

  return (
    <Container>
      <Grid container spacing={2} direction="column" justify="center" alignItems="center">
        <Grid item xs={10}>
            <MobileStepper
                activeStep={getStepperPosition(DocumentVerificationStep.ScanBackImageIntro)}
                nextButton={null}
                backButton={null}
                variant="dots"
                steps={3}
                position="static"
            />
        </Grid>
        <Grid item xs={10}>
          <Typography align="center" component="h1" variant="h5" className={classes.title}>
               {getStringTranslation(stringKeys.verifyYourIdentity)}
          </Typography>
          <Typography align="center" variant="subtitle1" className={classes.subtitle}>
               {getStringTranslation(stringKeys.scanBackDocument)}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <img alt="document" className={classes.image} src="images/card-id-back.svg" />
        </Grid>

        <Grid item xs={12} className={classes.buttonWrapper}>
          <FilledButton variant="contained" color="primary" onClick={handleNext}>
              {getStringTranslation(stringKeys.continueString)}
          </FilledButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ScanBackImageIntro;
