import { default as React, useContext } from 'react';

import { DocumentVerificationStep, DocumentVerificationContext } from '../../containers/DocumentVerification';
import { Grid, Typography, List, ListItem, Container, makeStyles } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';

import { FilledButton } from "../../styles/FilledButton";
import {getStringTranslation, stringKeys} from "../../helpers/translate";
import {getStepsCount} from "../../containers/DocumentVerification/StepsResolver";
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(7)
  },
  subtitle: {
    lineHeight: '1.5em'
  },
    stepper: {
      line: {
        width: '50px !important'
      }
    },
  image: {
    height: theme.spacing(9),
    margin: theme.spacing(1, 0, 0)
  },
  ul: {
    margin: theme.spacing(-1, 0, 3),
    listStyle: 'decimal inside none'
  },
  li: {
    display: 'list-item',
    lineHeight: '1.5em'
  },
  buttonWrapper: {
    width: '100%',
    textAlign: 'center',
  }
}));

const ScanFrontImageIntro = (): JSX.Element => {
  const classes = useStyles();
  const { onChangeStep, step } = useContext(DocumentVerificationContext);

  const handleNext = (): void => {
    onChangeStep(DocumentVerificationStep.ScanFrontImageStart);
  };

  return (
    <Container>
      <Grid container spacing={2} direction="column" justify="center" alignItems="center">
        <Grid item xs={10} >
            <MobileStepper
                activeStep={0}
                nextButton={null}
                backButton={null}
                variant="dots"
                steps={getStepsCount()}
                position="static"
            />
        </Grid>
        <Grid item xs={10}>
          <Typography align="center" component="h1" variant="h5" className={classes.title}>
              {getStringTranslation(stringKeys.verifyYourIdentity)}
          </Typography>
          <Typography align="center" variant="subtitle1" className={classes.subtitle}>
              {getStringTranslation(stringKeys.scanFrontDocument)}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <img alt="document" className={classes.image} src="images/card-id-front.svg" />
        </Grid>
        <Grid item xs={10}>
          <List className={classes.ul}>
            <ListItem disableGutters className={classes.li}>
                {getStringTranslation(stringKeys.placeIdOnDarkSurface)}
            </ListItem>
            <ListItem disableGutters className={classes.li}>
                {getStringTranslation(stringKeys.makeSureAllIdCornersVisible)}
            </ListItem>
            <ListItem disableGutters className={classes.li}>
                {getStringTranslation(stringKeys.adjustAngle)}
            </ListItem>
          </List>
        </Grid>
        <Grid className={classes.buttonWrapper} item xs={12}>
          <FilledButton variant="contained" color="primary" onClick={handleNext}>
              {getStringTranslation(step === DocumentVerificationStep.ScanFrontImageIntro
                  ? stringKeys.start : stringKeys.continueString)}
          </FilledButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ScanFrontImageIntro;
