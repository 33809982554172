import {DocumentVerificationStep} from "./DocumentVerificationProps";


export interface StepInfo {
    current: DocumentVerificationStep
    next: DocumentVerificationStep | null
}

export interface StepsHolder {
    stepsCount: number
    needToCaptureBackSide: boolean
    needToCaptureSelfie: boolean
    steps: StepInfo[]
}

export const stepsHolder: StepsHolder = {
    stepsCount: 1,
    steps: [],
    needToCaptureBackSide: false,
    needToCaptureSelfie: false
};


export function initSteps(needToCaptureBackSide: number, needToCaptureSelfie: number) {

    stepsHolder.needToCaptureBackSide = needToCaptureBackSide === 1;
    stepsHolder.needToCaptureSelfie = needToCaptureSelfie === 1;

    if (needToCaptureBackSide === 1) {
        stepsHolder.stepsCount++;
    }

    if (needToCaptureSelfie === 1) {
        stepsHolder.stepsCount++;
    }


    if (needToCaptureBackSide === 1 && needToCaptureSelfie === 1) {
        stepsHolder.steps.push({
            current: DocumentVerificationStep.ScanFrontImageComplete,
            next: DocumentVerificationStep.ScanBackImageIntro,
        });

        stepsHolder.steps.push({
            current: DocumentVerificationStep.ScanBackImageComplete,
            next: DocumentVerificationStep.TakeSelfieIntro,
        });
    }

    if (needToCaptureBackSide === 1 && needToCaptureSelfie === 0) {
        stepsHolder.steps.push({
            current: DocumentVerificationStep.ScanFrontImageComplete,
            next: DocumentVerificationStep.ScanBackImageIntro,
        });
    }

    if (needToCaptureBackSide === 0 && needToCaptureSelfie === 1) {
        stepsHolder.steps.push({
            current: DocumentVerificationStep.ScanFrontImageComplete,
            next: DocumentVerificationStep.TakeSelfieIntro,
        });
    }
}

export function getStepsCount(): number {
    return stepsHolder.stepsCount;
}

export function getStepperPosition(step: DocumentVerificationStep): number {
    if ([
        DocumentVerificationStep.ScanFrontImageIntro,
        DocumentVerificationStep.ScanFrontImageStart,
        DocumentVerificationStep.ScanFrontImageComplete,
        DocumentVerificationStep.ScanFrontImageError,
    ].indexOf(step) !== -1) {
        return 0;
    }

    if ([
        DocumentVerificationStep.ScanBackImageIntro,
        DocumentVerificationStep.ScanBackImageStart,
        DocumentVerificationStep.ScanBackImageError,
        DocumentVerificationStep.ScanBackImageComplete,
    ].indexOf(step) !== -1 && stepsHolder.needToCaptureBackSide) {
        return 1;
    }

    const selfieStep = [
        DocumentVerificationStep.TakeSelfieIntro,
        DocumentVerificationStep.TakeSelfieStart,
        DocumentVerificationStep.TakeSelfieError,
        DocumentVerificationStep.TakeSelfieComplete,
    ];

    if (selfieStep.indexOf(step) !== -1 && stepsHolder.needToCaptureSelfie && stepsHolder.needToCaptureBackSide) {
        return 3;
    }

    if (selfieStep.indexOf(step) !== -1 && stepsHolder.needToCaptureSelfie && !stepsHolder.needToCaptureBackSide) {
        return 2;
    }

    return 1;
}

export function getNextStep(currentStep: DocumentVerificationStep): DocumentVerificationStep | null {
    const current = stepsHolder.steps.find((step: StepInfo) => step.current === currentStep);

    if (current) {
        return current.next;
    }

    return null;
}