import { default as React, FC, useContext } from 'react';
import { DocumentVerificationStep, DocumentVerificationContext } from '../../containers/DocumentVerification';
import { AppContext } from '../../containers/AppProvider';
import { PhotosActions, PhotosActionTypes } from '../../containers/AppProvider/reducers';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { ImageType } from './ImageResultProps';
import {FilledButton} from "../../styles/FilledButton";
import {OutlinedButton} from "../../styles/OutlinedButton";
import MobileStepper from '@material-ui/core/MobileStepper';
import {getStringTranslation, stringKeys} from "../../helpers/translate";
import {getStepperPosition, getStepsCount} from "../../containers/DocumentVerification/StepsResolver";

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    marginBottom: theme.spacing(5)
  },
  image: {
    height: 'auto',
    width: '100%',
    maxWidth: 360,
    margin: 'auto'
  },
  buttonWrapper: {
    marginTop: theme.spacing(4)
  },
  textCenter: {
    textAlign: 'center'
  }
}));

type ImageResultProps = {
  tryAgainStep: DocumentVerificationStep;
  nextStep: DocumentVerificationStep;
  imageType: ImageType;
};

const ImageResult: FC<ImageResultProps> = ({ tryAgainStep, nextStep, imageType }): JSX.Element => {
  const classes = useStyles();
  const { dispatch, state } = useContext(AppContext);
  const { onChangeStep, onChangeNumOfAttempts } = useContext(DocumentVerificationContext);

  const handleSubmit = (): void => {
    onChangeNumOfAttempts(0);
    onChangeStep(nextStep);
  };

  const handleTryAgain = (): void => {
    let photosActions: PhotosActions;

    if (tryAgainStep === DocumentVerificationStep.ScanFrontImageStart) {
      photosActions = {
        type: PhotosActionTypes.AddFrontImage,
        payload: {
          frontImage: ''
        }
      };
    } else if (tryAgainStep === DocumentVerificationStep.ScanBackImageStart) {
      photosActions = {
        type: PhotosActionTypes.AddBackImage,
        payload: {
          backImage: ''
        }
      };
    } else if (tryAgainStep === DocumentVerificationStep.TakeSelfieStart) {
      photosActions = {
        type: PhotosActionTypes.AddSelfie,
        payload: {
          selfie: ''
        }
      };
    } else {
      throw new Error('Next step is not available');
    }

    dispatch(photosActions);
    onChangeNumOfAttempts(0);
    onChangeStep(tryAgainStep);
  };

  return (
    <Container>
      <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <MobileStepper
                  activeStep={getStepperPosition(tryAgainStep)}
                  nextButton={null}
                  backButton={null}
                  variant="dots"
                  steps={getStepsCount()}
                  position="static"
              />

        <Grid item xs={12} className={classes.titleWrapper}>
          <Typography align="center" component="h1" variant="h4">
              {getStringTranslation(stringKeys.verifyYourIdentity)}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <img alt="front" className={classes.image} src={state.photo[imageType]} />
        </Grid>
        <Grid container justify="center" alignItems="center" spacing={2} className={classes.buttonWrapper}>
          <Grid item xs={12} className={classes.textCenter}>
              <FilledButton variant="contained" color="primary" onClick={handleSubmit}>
                  {getStringTranslation(nextStep === DocumentVerificationStep.Finish ?
                      stringKeys.submit : stringKeys.continueString)}
              </FilledButton>
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <OutlinedButton  variant="outlined" color="primary" onClick={handleTryAgain}>
                {getStringTranslation(stringKeys.tryAgain)}
            </OutlinedButton>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImageResult;
