import {default as React, FC, useContext, useRef, useState} from 'react';
import { DocumentVerificationContext, DocumentVerificationStep } from '../../containers/DocumentVerification';
import { NumberOfAttempts } from '../../services/trulioo/contants';
import { AppContext } from '../../containers/AppProvider';
import { PhotosActions, PhotosActionTypes } from '../../containers/AppProvider/reducers';
import { toBase64 } from '../../helpers';
import Swal from 'sweetalert2';
import {getStringTranslation, stringKeys} from "../../helpers/translate";
import {MAX_FILE_SIZE_IN_BYTES} from "../../components/constants";

const swalOptions = {
    customClass: {
        confirmButton: 'try-again',
        htmlContainer: 'swal-content',
        container: 'swal'
    },
    allowOutsideClick: false,
};

type ImageCaptureErrorProps = {
  tryAgainStep: DocumentVerificationStep;
  nextStep: DocumentVerificationStep;
};

const ImageCaptureError: FC<ImageCaptureErrorProps> = ({ tryAgainStep, nextStep }): JSX.Element => {
  const { dispatch } = useContext(AppContext);
  const { onChangeStep, attempts } = useContext(DocumentVerificationContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const [alertShown, setAlertShown] = useState<boolean>(false);
  const [fileAlertShown, setFileAlertShown] = useState<boolean>(false);
  const handleTryAgain = (): void => {
    onChangeStep(tryAgainStep);
  };

  const handleManualCapture = (): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleSelectedFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.files?.length) {
      Swal.close();
      const file = event.target.files[0];

     if(file.size > MAX_FILE_SIZE_IN_BYTES && !fileAlertShown) {
         setFileAlertShown(true);
         Swal.fire({
             ...swalOptions,
             title:  getStringTranslation(stringKeys.fileValidationError),
             html: getStringTranslation(stringKeys.fileSizeToBig),
             confirmButtonText: getStringTranslation(stringKeys.tryAgain)
         }).then(() => {
             setFileAlertShown(false);
             handleManualCapture();
             return false;
         });

         return;
     }

      const img = await toBase64(file);

      let photosActions: PhotosActions;

      if (nextStep === DocumentVerificationStep.ScanFrontImageComplete) {
        photosActions = {
          type: PhotosActionTypes.AddFrontImage,
          payload: {
            frontImage: img
          }
        };
      } else if (nextStep === DocumentVerificationStep.ScanBackImageComplete) {
        photosActions = {
          type: PhotosActionTypes.AddBackImage,
          payload: {
            backImage: img
          }
        };
      } else if (nextStep === DocumentVerificationStep.TakeSelfieComplete) {
        photosActions = {
          type: PhotosActionTypes.AddSelfie,
          payload: {
            selfie: img
          }
        };
      } else {
        throw new Error('Next step is not available');
      }

      dispatch(photosActions);
      onChangeStep(nextStep);
    }
  };

    if (NumberOfAttempts > attempts && !alertShown) {
        setAlertShown(true);
        Swal.fire({
            ...swalOptions,
            title:  getStringTranslation(stringKeys.checkQuality),
            html: '<img src="/images/capture-error.svg" /> ' +
            '<p>' + getStringTranslation(stringKeys.makeSureOfClearDetails) + '</p>',
            confirmButtonText: getStringTranslation(stringKeys.tryAgain)
        }).then(() => {
            handleTryAgain();
        })
        return <></>
    }

    if (!alertShown) {
        setAlertShown(true);
        Swal.fire({
            ...swalOptions,
            title: 'Oops',
            html: '<p>' + getStringTranslation(stringKeys.seemsLikeWeHaveTrouble) + '</p>' +
            ' <img src="/images/capture-error.svg" /> ',
            confirmButtonText: getStringTranslation(stringKeys.takePicture),
            preConfirm: function() {
                handleManualCapture();
                return false;
            }
        })
    }

    if(fileAlertShown) {
        return <></>
    }

    return <>
        <input
            accept="image/x-png,image/gif,image/jpeg"
            ref={inputRef}
            style={{display: 'none'}}
            type="file"
            multiple={false}
            onChange={handleSelectedFile}
        />
    </>
};

export default ImageCaptureError;
