import {makeStyles} from "@material-ui/core";

export const finishStepStyles = makeStyles((theme) => ({
    imageWrapper: {
        marginBottom: theme.spacing(5)
    },
    image: {
        height: theme.spacing(9)
    },
    title: {
        fontWeight: theme.typography.fontWeightBold
    }
}));

export const MAX_FILE_SIZE_IN_BYTES = 4194304;



