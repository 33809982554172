export enum PhotosActionTypes {
  AddFrontImage = 'ADD_FRONT_IMAGE',
  AddBackImage = 'ADD_BACK_IMAGE',
  AddSelfie = 'ADD_SELFIE',
  SetFlowType = 'SET_FLOW_TYPE',
}

export type PhotosReducerState = {
  isMobileFlow: boolean;
  frontImage: string;
  backImage: string;
  selfie: string;
};

type PhotosPayload = {
  [PhotosActionTypes.SetFlowType]: {
      isMobileFlow: boolean;
  };
  [PhotosActionTypes.AddFrontImage]: {
    frontImage: string;
  };
  [PhotosActionTypes.AddBackImage]: {
    backImage: string;
  };
  [PhotosActionTypes.AddSelfie]: {
    selfie: string;
  };
};

export const PhotosReducerInitialState: PhotosReducerState = {
  isMobileFlow: false,
  frontImage: '',
  backImage: '',
  selfie: ''
};

export type PhotosActions = ActionMap<PhotosPayload>[keyof ActionMap<PhotosPayload>];

export const photosReducer = (state: PhotosReducerState, action: PhotosActions) => {
  switch (action.type) {
    case PhotosActionTypes.SetFlowType:
      return {
        ...state,
          isMobileFlow: action.payload.isMobileFlow
          };
    case PhotosActionTypes.AddFrontImage:
      return {
        ...state,
        frontImage: action.payload.frontImage
      };
    case PhotosActionTypes.AddBackImage:
      return {
        ...state,
        backImage: action.payload.backImage
      };
    case PhotosActionTypes.AddSelfie:
      return {
        ...state,
        selfie: action.payload.selfie
      };
    default:
      return state;
  }
};
