export const NumberOfAttempts = 3;
export const defaultTimeout = 15000;

export enum CaptureType {
  DocumentBack,
  DocumentFront,
  Passport,
  Barcode,
  LivePhoto
}

// Default hint messages can be found at the beginning of this file
// Hint messages can be customized:
// GlobalGatewayCapture.GlobalGatewayHints.GLOBALGATEWAY_ERROR_FOUR_CORNER = "Cannot Find Document";

// const GlobalGatewayHints = {
//   GLOBALGATEWAY_HEAD_OUTSIDE: 'Place Face in Oval',
//   GLOBALGATEWAY_HEAD_SKEWED: 'Look Straight Ahead',
//   GLOBALGATEWAY_AXIS_ANGLE: 'Hold Phone Upright',
//   GLOBALGATEWAY_HEAD_TOO_CLOSE: 'Move Farther Away',
//   GLOBALGATEWAY_HEAD_TOO_FAR: 'Get Closer',
//   GLOBALGATEWAY_STAY_STILL: 'Hold Still',
//   GLOBALGATEWAY_STOP_SMILING: 'Stop Smiling',
//   GLOBALGATEWAY_SMILE: 'Smile!',
//   GLOBALGATEWAY_READY_POSE: 'Hold it There',
//   GLOBALGATEWAY_NO_FACE_FOUND: 'No Face Detected',
//   GLOBALGATEWAY_ERROR_GLARE: 'Reduce Glare',
//   GLOBALGATEWAY_ERROR_FOUR_CORNER: 'Document Not Found',
// };

export const captureTypeOption = [
  { value: 'DocumentFront', label: 'ID Document Front' },
  { value: 'DocumentBack', label: 'ID Document Back' },
  { value: 'Passport', label: 'Passport' },
  { value: 'Barcode', label: 'Barcode (US, Canada only)' },
  { value: 'LivePhoto', label: 'Live Photo' }
];

export const captureMode = [
  { value: 'Auto', label: 'Auto' },
  { value: 'Manual', label: 'Manual' }
];

export const startMessage = 'Starting Capture';
