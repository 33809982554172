import React, { createContext, useReducer, Dispatch } from 'react';
import { PhotosReducerState, PhotosActions, PhotosReducerInitialState, photosReducer } from './reducers';

type InitialStateType = {
  photo: PhotosReducerState;
};

const initialState = {
  photo: PhotosReducerInitialState
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<PhotosActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = ({ photo }: InitialStateType, action: PhotosActions) => ({
  photo: photosReducer(photo, action)
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext };
