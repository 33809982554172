export const NumberOfAttempts = 2;

export enum DocumentVerificationStep {
  ScanFrontImageIntro,
  ScanFrontImageStart,
  ScanFrontImageComplete,
  ScanFrontImageError,

  ScanBackImageIntro,
  ScanBackImageStart,
  ScanBackImageComplete,
  ScanBackImageError,

  TakeSelfieIntro,
  TakeSelfieStart,
  TakeSelfieComplete,
  TakeSelfieError,

  Finish
}
