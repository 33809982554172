import { default as React, FC } from 'react';
import { DocumentVerificationStep } from '../../containers/DocumentVerification';
import { ScanFrontImageIntro, ScanFrontImageComplete, ScanFrontImageError, ScanFrontImageStart } from '../FrontImage';
import { ScanBackImageIntro, ScanBackImageComplete, ScanBackImageError, ScanBackImageStart } from '../BackImage';
import { TakeSelfieIntro, TakeSelfieComplete, TakeSelfieStart } from '../Selfie';
import Finish from '../Finish/Finish';
import TakeSelfieError from '../Selfie/TakeSelfieError';

type StepResolverProps = {
  step: DocumentVerificationStep;
};

const StepResolver: FC<StepResolverProps> = ({ step }): JSX.Element => {
  console.log(`Current step: ${step}`);

  switch (step) {
    case DocumentVerificationStep.ScanFrontImageIntro:
      return <ScanFrontImageIntro />;
    case DocumentVerificationStep.ScanFrontImageStart:
      return <ScanFrontImageStart />;
    case DocumentVerificationStep.ScanFrontImageComplete:
      return <ScanFrontImageComplete />;
    case DocumentVerificationStep.ScanFrontImageError:
      return <ScanFrontImageError />;
    case DocumentVerificationStep.ScanBackImageIntro:
      return <ScanBackImageIntro />;
    case DocumentVerificationStep.ScanBackImageStart:
      return <ScanBackImageStart />;
    case DocumentVerificationStep.ScanBackImageComplete:
      return <ScanBackImageComplete />;
    case DocumentVerificationStep.ScanBackImageError:
      return <ScanBackImageError />;
    case DocumentVerificationStep.TakeSelfieIntro:
      return <TakeSelfieIntro />;
    case DocumentVerificationStep.TakeSelfieStart:
      return <TakeSelfieStart />;
    case DocumentVerificationStep.TakeSelfieComplete:
      return <TakeSelfieComplete />;
    case DocumentVerificationStep.TakeSelfieError:
      return <TakeSelfieError />;
    case DocumentVerificationStep.Finish:
      return <Finish />;
    default:
      return <ScanFrontImageIntro />;
  }
};

export default StepResolver;
