import { default as React } from 'react';
import { DocumentVerificationStep } from '../../containers/DocumentVerification';
import { ImageType, ImageResult } from '../../shared';
import {getNextStep} from "../../containers/DocumentVerification/StepsResolver";

const ScanBackImageComplete = (): JSX.Element => {
  return (
    <ImageResult
      tryAgainStep={DocumentVerificationStep.ScanBackImageStart}
      nextStep={getNextStep(DocumentVerificationStep.ScanBackImageComplete) || DocumentVerificationStep.Finish}
      imageType={ImageType.BackImage}
    />
  );
};

export default ScanBackImageComplete;
