import { default as React, useContext } from 'react';
import { DocumentVerificationStep, DocumentVerificationContext } from '../../containers/DocumentVerification';
import { AppContext } from '../../containers/AppProvider';
import { PhotosActionTypes } from '../../containers/AppProvider/reducers';
import Trulioo from '../../services/trulioo';
import { CaptureType } from '../../services/trulioo/contants';

const TakeSelfieStart = (): JSX.Element => {
  const { dispatch } = useContext(AppContext);
  const { onChangeStep, onChangeNumOfAttempts, attempts } = useContext(DocumentVerificationContext);

  const handleImgSuccess = (res: string): void => {
    dispatch({
      type: PhotosActionTypes.AddSelfie,
      payload: {
        selfie: res
      }
    });
    onChangeNumOfAttempts(0);
    onChangeStep(DocumentVerificationStep.TakeSelfieComplete);
  };

  const handleError = (error: string): void => {
    console.log(error);
    onChangeNumOfAttempts(attempts + 1);
    onChangeStep(DocumentVerificationStep.TakeSelfieError);
  };

  return (
    <Trulioo captureType={CaptureType.LivePhoto} isAuto={true} onImgSuccess={handleImgSuccess} onError={handleError} />
  );
};

export default TakeSelfieStart;
