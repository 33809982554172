import { default as React, useContext } from 'react';
import { DocumentVerificationStep, DocumentVerificationContext } from '../../containers/DocumentVerification';
import { Container, Grid, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import { FilledButton } from "../../styles/FilledButton";
import {getStringTranslation, stringKeys} from "../../helpers/translate";
import {getStepperPosition, getStepsCount} from "../../containers/DocumentVerification/StepsResolver";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(7)
  },
  subtitle: {
    lineHeight: '1.5em',
    fontWeight: theme.typography.fontWeightBold
  },
  image: {
    height: theme.spacing(9),
    margin: theme.spacing(1, 0, 0)
  },
  ul: {
    margin: theme.spacing(-1, 0, 3),
    listStyle: 'decimal inside none'
  },
  li: {
    display: 'list-item',
    lineHeight: '1.5em'
  },
  buttonWrapper: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(3)
  }
}));

const TakeSelfieIntro = (): JSX.Element => {
  const classes = useStyles();
  const { onChangeStep } = useContext(DocumentVerificationContext);

  const handleNext = (): void => {
    onChangeStep(DocumentVerificationStep.TakeSelfieStart);
  };

  return (
    <Container>
      <Grid container spacing={2} direction="column" justify="center" alignItems="center">
        <Grid item xs={10} >
            <MobileStepper
                activeStep={getStepperPosition(DocumentVerificationStep.TakeSelfieIntro)}
                nextButton={null}
                backButton={null}
                variant="dots"
                steps={getStepsCount()}
                position="static"
            />
        </Grid>
        <Grid item xs={10}>
          <Typography align="center" component="h1" variant="h5" className={classes.title}>
              1. {getStringTranslation(stringKeys.verifyYourIdentity)}
          </Typography>
          <Typography align="center" variant="subtitle1" className={classes.subtitle}>
              2. {getStringTranslation(stringKeys.takeSelfie)}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <img alt="document" className={classes.image} src="images/card-id-front.svg" />
        </Grid>
        <Grid item xs={10}>
          <List className={classes.ul}>
            <ListItem disableGutters className={classes.li}>
                {getStringTranslation(stringKeys.makeSureFaceInOval)}
            </ListItem>
            <ListItem disableGutters className={classes.li}>
                {getStringTranslation(stringKeys.lookAndSmile)}
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} className={classes.buttonWrapper}>
          <FilledButton variant="contained" color="primary" onClick={handleNext}>
              {getStringTranslation(stringKeys.continueString)}
          </FilledButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TakeSelfieIntro;
