import React, { FC, useEffect } from 'react';
import { TruliooError, TruliooProps } from './TruliooProps';
import { CaptureType, defaultTimeout, startMessage } from './contants';
import {getStringTranslation, stringKeys} from "../../helpers/translate";

const Trulioo: FC<TruliooProps> = ({ captureType, isAuto, onError, onImgSuccess }): JSX.Element => {
  useEffect(() => {
    if (isAuto) {
      startCapture();
    }
  }, [isAuto]); // eslint-disable-line

  const handleImgSuccess = (res: string): void => {
    onImgSuccess(res);
  };

  const handleError = (errArray: Array<TruliooError>): void => {
    let errorMessage = '';
    if (errArray && errArray.length > 0) {
      // handle different error code here
      for (let i = 0; i < errArray.length; i += 1) {
        errorMessage += `Error Code ${errArray[i].code}: ${errArray[i].type} \n`;
      }
    } else {
      errorMessage = 'Capture timeout';
    }

    if (errorMessage !== '') {
      onError(errorMessage);
    }
  };

  (window as any).GlobalGatewayHints.GLOBALGATEWAY_ERROR_FOUR_CORNER = getStringTranslation(stringKeys.documentNotFound);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_HEAD_OUTSIDE = getStringTranslation(stringKeys.placeFaceInOval);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_SMILE = getStringTranslation(stringKeys.smile);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_NO_FACE_FOUND = getStringTranslation(stringKeys.noFaceDetected);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_HEAD_SKEWED = getStringTranslation(stringKeys.headSkewed);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_AXIS_ANGLE = getStringTranslation(stringKeys.axisAngle);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_HEAD_TOO_CLOSE = getStringTranslation(stringKeys.headTooClose);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_HEAD_TOO_FAR = getStringTranslation(stringKeys.headTooFar);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_STAY_STILL = getStringTranslation(stringKeys.stayStill);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_STOP_SMILING = getStringTranslation(stringKeys.stopSmiling);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_READY_POSE = getStringTranslation(stringKeys.readyPose);
  (window as any).GlobalGatewayHints.GLOBALGATEWAY_ERROR_GLARE = getStringTranslation(stringKeys.errorGlare);


    const startCapture = (): void => {
    // setError('');
    // setImgResult(undefined);
    switch (captureType) {
      case CaptureType.DocumentBack:
      case CaptureType.DocumentFront:
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (window as any).StartDocumentCapture(startMessage, defaultTimeout, isAuto, handleImgSuccess, handleError);
        break;
      case CaptureType.Passport:
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (window as any).StartPassportCapture(startMessage, defaultTimeout, isAuto, handleImgSuccess, handleError);
        break;
      case CaptureType.Barcode:
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (window as any).StartBarcodeCapture(startMessage, defaultTimeout, isAuto, handleImgSuccess, handleError);
        break;
      case CaptureType.LivePhoto:
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (window as any).StartSelfieCapture(startMessage, defaultTimeout, isAuto, handleImgSuccess, handleError);
        break;
      default:
        break;
    }
  };

  return <div id="trulioo" />;
};

export default Trulioo;
