import React, { FC } from 'react';
import { Grid, Container, makeStyles } from '@material-ui/core';
import { DocumentVerificationContext, DocumentVerificationProvider } from '../../containers/DocumentVerification';
import StepResolver from './StepResolver';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'inherit',
    padding: theme.spacing(0)
  },
  container: {
    height: 'inherit'
  }
}));

const DocumentVerification: FC = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container alignItems="center" justify="center" className={classes.container}>
        <Grid item>
          <DocumentVerificationProvider>
            <DocumentVerificationContext.Consumer>
              {({ step }) => <StepResolver step={step} />}
            </DocumentVerificationContext.Consumer>
          </DocumentVerificationProvider>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DocumentVerification;
