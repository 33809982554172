import { default as React } from 'react';
import { ImageCaptureError } from '../../shared';
import { DocumentVerificationStep } from '../../containers/DocumentVerification';

const ScanFrontImageError = (): JSX.Element => {
  return (
    <ImageCaptureError
      nextStep={DocumentVerificationStep.ScanFrontImageComplete}
      tryAgainStep={DocumentVerificationStep.ScanFrontImageStart}
    />
  );
};

export default ScanFrontImageError;
