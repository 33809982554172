import React from 'react';
import { AppProvider } from './containers/AppProvider';
import { DocumentVerification } from './components/DocumentVerification';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { theme as ocrTheme } from './styles';

const theme = createMuiTheme(ocrTheme);

export default (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <DocumentVerification />
      </AppProvider>
    </ThemeProvider>
  );
};
