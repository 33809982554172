import {default as React, FC, useContext, useEffect, useState} from 'react';
import queryString from 'query-string';
import { DocumentVerificationContext, DocumentVerificationContextI } from './DocumentVerificationContext';
import { DocumentVerificationStep } from './DocumentVerificationProps';
import { scanQrCodeInBackend } from './api';
import { QrCodeScanState } from "./QrCodeScanState";
import { AppContext } from "../AppProvider";
import { PhotosActionTypes } from "../AppProvider/reducers";
import { loadLocalizationFile, getStringTranslation, stringKeys } from "../../helpers/translate";
import { TranslateLoadingState } from "./TranslateLoadingState";
import {initSteps} from "./StepsResolver";
interface DocumentVerificationProviderProps {}

export const DocumentVerificationProvider: FC<DocumentVerificationProviderProps> = ({ children }): JSX.Element => {
  const [step, setStep] = useState<DocumentVerificationStep>(DocumentVerificationStep.ScanFrontImageIntro);
  const [attempts, setAttempts] = useState<number>(0);
  const { dispatch } = useContext(AppContext);
  const [translationLoadingState, setTranslationLoadingState] = useState<TranslateLoadingState>(TranslateLoadingState.Waiting);
  const [scanState, setScanState] = useState<QrCodeScanState>(QrCodeScanState.Waiting);
  const {token = null, lang = null, region = null} = queryString.parse(window.location.search);
  const {documentHasBackSide = 0, needSelfieCapture = 0} = queryString.parse(window.location.search);

  useEffect(() => {
        initSteps(Number(documentHasBackSide), Number(needSelfieCapture));
    }, [documentHasBackSide, needSelfieCapture]);

  useEffect( () => {
      if(!lang || !region) {
          return;
      }

      (async () => {
          setTranslationLoadingState(TranslateLoadingState.Loading);
          loadLocalizationFile(lang as string, region as string).then(() => setTranslationLoadingState(TranslateLoadingState.Done));
      })();

        if (scanState === QrCodeScanState.Waiting) {
            setScanState(QrCodeScanState.Scaning);
            scanQrCodeInBackend(token as string).then((data) => {
                dispatch({
                    type: PhotosActionTypes.SetFlowType,
                    payload: {
                        isMobileFlow: data.hasOwnProperty('isMobileFlow') && data.isMobileFlow === 1
                    }
                });

                setScanState(data.hasOwnProperty('success') && data.success ? QrCodeScanState.Done : QrCodeScanState.Error);
            }).catch(() => {
                setScanState(QrCodeScanState.Error)
            });
        }}, [scanState, token, lang, region, dispatch]);

  const handleChangeStep = (step: DocumentVerificationStep): void => {
    setStep(step);
  };

  const handleChangeNumOfAttempts = (nextAttemptsValue: number): void => {
    setAttempts(nextAttemptsValue);
  };

  const contextValues = (): DocumentVerificationContextI['Combined'] => {
    return {
      step,
      onChangeStep: handleChangeStep,
      attempts,
      onChangeNumOfAttempts: handleChangeNumOfAttempts
    };
  };

    if (!lang) {
        return <h2>Required query parameter `lang` is omitted</h2>;
    }

    if (!region) {
        return <h2>Required query parameter `region` is omitted</h2>;
    }

    if (!token) {
        return <h2>Required query parameter `token` is omitted</h2>;
    }

    if(translationLoadingState !== TranslateLoadingState.Done) {
        return <></>;
    }

    if (scanState === QrCodeScanState.Error) {
        return <h2>{getStringTranslation(stringKeys.errorPleaseTryAgain)}</h2>;
    }

    if (scanState === QrCodeScanState.Waiting || scanState === QrCodeScanState.Scaning) {
        return <h2>{getStringTranslation(stringKeys.pleaseWait)}</h2>;
    }

  return (
    <DocumentVerificationContext.Provider value={contextValues()}>{children}</DocumentVerificationContext.Provider>
  );
};

export default DocumentVerificationProvider;
