import { default as React } from 'react';
import { ImageCaptureError } from '../../shared';
import { DocumentVerificationStep } from '../../containers/DocumentVerification';

const ScanBackImageError = (): JSX.Element => {
  return (
    <ImageCaptureError
      nextStep={DocumentVerificationStep.ScanBackImageComplete}
      tryAgainStep={DocumentVerificationStep.ScanBackImageStart}
    />
  );
};

export default ScanBackImageError;
