import { createContext } from 'react';
import { DocumentVerificationStep } from './DocumentVerificationProps';

export interface DocumentVerificationContextI {
  readonly State: {
    step: DocumentVerificationStep;
    attempts: number;
  };
  readonly Actions: {
    onChangeStep: (step: DocumentVerificationStep) => void;
    onChangeNumOfAttempts: (nextAttemptValue: number) => void;
  };
  readonly Combined: DocumentVerificationContextI['State'] & DocumentVerificationContextI['Actions'];
}

export const DocumentVerificationContext = createContext<DocumentVerificationContextI['Combined']>({
  attempts: 0,
  step: DocumentVerificationStep.ScanFrontImageIntro,
  onChangeStep: () => undefined,
  onChangeNumOfAttempts: () => undefined
});
