import { default as React } from 'react';
import { ImageCaptureError } from '../../shared';
import { DocumentVerificationStep } from '../../containers/DocumentVerification';

const TakeSelfieError = (): JSX.Element => {
  return (
    <ImageCaptureError
      nextStep={DocumentVerificationStep.TakeSelfieComplete}
      tryAgainStep={DocumentVerificationStep.TakeSelfieStart}
    />
  );
};

export default TakeSelfieError;
