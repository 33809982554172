
let translations: any = {};

export const loadLocalizationFile = async (lang: string, region: string) => {
    const host = window.location.hostname;

    const domainParts = host.split('.');

    domainParts.shift();

    const url = 'https://my.' + domainParts.join('.') + '/api/sites/default/files/translations/capture-app/' + lang + '-' + region + '.json';

    const response = await fetch(url);
    translations = await response.json();
}

export const stringKeys: any = {
    verify: 'capture_app_verify',
    fileValidationError: 'capture_app_file_validation_error',
    fileSizeToBig: 'capture_app_file_size_to_big',
    start: 'capture_app_start',
    verifyYourIdentity: 'capture_app_verify_your_identity',
    scanFrontDocument: 'capture_app_scan_front_document',
    placeIdOnDarkSurface: 'capture_app_place_id_on_dark_surface',
    makeSureAllIdCornersVisible: 'capture_app_make_sure_all_Id_corners_visible',
    adjustAngle: 'capture_app_adjust_angle',
    makeSureImageInFocus: 'capture_app_make_sure_image_in_focus',
    continueString: 'capture_app_continue',
    documentNotFound: 'capture_app_document_not_found',
    checkQuality: 'capture_app_check_quality',
    makeSureOfClearDetails: 'capture_app_make_sure_of_clear_details',
    tryAgain: 'capture_app_try_again',
    seemsLikeWeHaveTrouble: 'capture_app_seems_like_we_have_trouble',
    takePicture: 'capture_app_take_picture',
    takeSelfie: 'capture_app_take_selfie',
    makeSureFaceInOval: 'capture_app_make_sure_face_in_oval',
    lookAndSmile: 'capture_app_look_and_smile',
    submit: 'capture_app_submit',
    placeFaceInOval: 'capture_app_place_face_in_oval',
    noFaceDetected: 'capture_app_no_face_detected',
    errorPleaseTryAgain: 'capture_app_error_please_try_again',
    scanBackDocument: 'capture_app_scan_back_document',
    pleaseWait: 'capture_app_please_wait',
    smile: 'capture_app_smile',
    uploadingPleaseWait: 'capture_app_uploading',
    continueVerify: 'capture_app_continue_verify',
    waitingUpload: 'capture_app_waiting_for_upload',
    desktopFlowSuccess: 'capture_app_success_message_desktop_flow',
    mobileFlowSuccess: 'capture_app_success_message_mobile_flow',
    headSkewed: 'capture_app_head_skewed',
    axisAngle: 'capture_app_axis_angle',
    headTooClose: 'capture_app_head_to_close',
    headTooFar: 'capture_app_head_too_far',
    stayStill: 'capture_app_stay_still',
    stopSmiling: 'capture_app_stop_smiling',
    readyPose: 'capture_app_ready_pose',
    errorGlare: 'capture_app_error_glare',
}

export const getStringTranslation = (key: any) => {
    if (!translations.hasOwnProperty(key)) {
        return key
    }

    return translations[key];
}