export const scanQrCodeInBackend = async (token: string) => {
    return (await makeApiRequest(token, "GET", '/ocr/capture-app/scan-qr', {})).json();
};

type FileUploadData = {
    [key: string]: string;
};

export const uploadScanedImages = async (token: string, completeFlow: boolean, frontDocument: string, backDocument?: string, selfie?: string,) => {

    const files: FileUploadData = {
        frontDocument: frontDocument
    };

    if (selfie && selfie.trim().length > 0) {
        files['selfie'] = selfie;
    }

    if (backDocument && backDocument.trim().length > 0) {
        files['backDocument'] = backDocument;
    }

    return (await makeApiRequest(token, "POST", '/ocr/capture-app/upload-scans', {
        completeFlow: completeFlow,
        files: files
    })).json();
};

const makeApiRequest = (token: string, method: "GET" | "POST", path: string, data: any) => {
    if (!process.env.REACT_APP_API_HOST) {
        throw new Error('REACT_API_HOST is not defined');
    }

    let requestData: any = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    if (method === 'POST') {
        requestData.body = JSON.stringify(data);
    }

    return fetch(path + '?token=' + encodeURIComponent(token), requestData);
}