import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const OutlinedButton = styled(Button)({
    borderRadius: 4,
    color:'#f27023',
    borderColor: '#f27023',
    boxShadow: 'none',
    fontSize: 14,
    width: '80%',
    marginRight: '10px',
    marginLeft: '10px',
    height: 30,
    padding: '0 30px',
    textTransform: 'none'
});